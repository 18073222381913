<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"> Configurar Acesso à API Olimpo Pay</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card">
        <div class="card-body" v-if="!docsOk">
          <div class="row">
            <div class="col-md-12 text-center">
              <ul class="list-unstyled my-4">
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                        <i class="fas fa-arrow-right"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2 text-md">Antes de ter acesso à este recurso, é necessário verificar a conta, completando o seu cadastro.</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-md-12 text-center">
              <router-link to="/pages/user" class="btn btn-primary">
                <span class="btn-label"><i class="fas fa-tasks"></i> COMPLETAR CADASTRO</span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="card-body" v-else>
          <div class="row">
            <div class="col-md-6">
              <div class="row d-flex align-items-center ">
                <div class="col-md-6 text-center pb-5 pt-5">
                  <i class="fas fa-plug fa-9x text-primary"></i>
                </div>
                <div class="col-md-6 text-center">
                  <div class="row">
                    <button type="button" class="btn btn-primary mt-2" @click="generateNew">
                      <i class="fas fa-key"></i>
                      Chave de Acesso
                    </button>
                  </div>
                  <div class="row">
                    <button type="button" class="btn btn-primary mt-2" @click="setWebhook" v-bind:disabled="!haveAccess">
                      <i class="fas fa-bell"></i>
                      Definir Webhook
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <card
                header-classes="bg-transparent"
                body-classes="px-lg-7"
                class="card-pricing border-0 text-center mb-4">

                <!--Header-->
                <h4 slot="header" class="text-uppercase ls-1 text-primary py-3 mb-0">Integre sua solução com a Olimpo Pay!</h4>

                <!--Body-->
                <div class="display-2">R$ 00,00</div>
                <span class=" text-muted">por chamada</span>
                <br>
                <span class="text-warning text-sm-right">(taxas de operações se aplicam)</span>
                <ul class="list-unstyled my-4">
                  <li>
                    <div class="d-flex text-left">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">Utilize nossa API para integrar seu negócio com nossas soluções.</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex text-left">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">Receba notificações de recebimentos em seu webhook.</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex text-left">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">Você paga apenas tarifas definidas nas operações financeiras. O uso da API é gratuito!</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex text-left">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span
                          class="pl-2 text-sm">Nossa equipe técnica está à disposição em caso de dúvidas.</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </card>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
</template>
<script>
  import {failWs, getWs, postWs, putWs} from '@/ws.service'
  import {isNullOrEmpty} from "@/util/core.utils"

  export default {
    components: {

    },
    data() {
      return {
        haveAccess: false,
        ci: null,
        cs: null,
        wh: null
      };
    },
    created() {
      this.docsOk = localStorage.getItem("docsOk") == 'true'
      if (!this.docsOk) {
        return
      }
      this.checkAccess()
    },
    methods: {
      isNullOrEmpty,
      generateNew(){
        this.$swal({
          title: 'Aviso!',
          text: 'Gerar novas chaves de acesso invalidará chaves anteriores. Deseja continuar?',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-warning btn-fill',
            cancelButton: 'btn btn-neutral btn-fill'
          },
          showConfirmButton: true,
          showCancelButton: true,
          icon: 'warning'
        }).then((result) => {
          if(result.isConfirmed){
            this.callGenerateNewAccess()
          }
        })
      },

      checkAccess() {
        getWs("/api-access-manager/check-access",
          true, null, (response) => {this.haveAccess = true}, (error) => {this.haveAccess = false})
      },
      callGenerateNewAccess() {
        postWs("/api-access-manager/generate-access", true, null, null,
          (response) => {
            this.haveAccess = true
            this.$swal({
              title: 'Sucesso!',
              html:"Suas chaves de acesso foram geradas com sucesso." +
                "<br><b>Guarde-as em segurança!</b>" +
                "<br>Para utiliza-las, adicione nos headers os campos \"ci\" (Client ID) e \"cs\" (Client Secret) em suas chamadas:" +
                "<div class='text-left'>"+
                "<br><span class='pl-5 text-muted'>Client ID: </span> " +
                "<br>" +
                "<span class='pl-6'>"+ response.data.clientId + "</span>" +
                "<br>" +
                "<span class='pl-5 text-muted'>Client Secret: </span> " +
                "<br>" +
                "<span class='pl-6'>" + response.data.clientSecret + "</span>" +
                "<br>" +
                "</div>",
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-info btn-fill',
              },
              timer: 60*1000,
              timerProgressBar: true,
              showConfirmButton: true,
              icon: 'info'
            })
          },
          (error) => {
            if (error.response.status == 412) {
              return;
            }
            let text = 'Falha ao gerar chave! Tente mais tarde.'
            this.$swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-warning btn-fill'
              },
              icon: 'warning'
            })
          },
        true)
      },
      setWebhook(){
        getWs("/api-access-manager/webhook",
          true, null,
          (response)=>{
            this.showWhConfigSwal(response.data.urlWebhook)
          },
          (error) => {
            if (error.response.status == 412) {
              return;
            }
            this.showWhConfigSwal("")
          },
        true)
      },
      showWhConfigSwal(urlWebhook) {
        this.$swal({
          title: 'Informe a url do Webhook:',
          input: "url",
          inputValue: urlWebhook,
          inputValidator(inputValue) {
            if(!inputValue){
              return "Informe uma url para receber as notificações!"
            }
          },
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-neutral btn-fill',
            cancelButton: 'btn btn-warning btn-fill'
          },
          showConfirmButton: true,
          showCancelButton:true,
          icon: 'info'
        }).then((result) =>{
          if(result.isConfirmed && result.value){
            putWs("/api-access-manager/webhook",
              true, {url : result.value},
              (response)=>{
                this.$swal({
                  title: 'Sucesso!',
                  text: "Endereço definido com sucesso",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-info btn-fill',
                  },
                  timer: 5*1000,
                  showConfirmButton: true,
                  icon: 'info'
                })
              },
              (error) => {
                if (error.response.status == 412) {
                  return;
                }
                let text = 'Falha ao definir endereço! Tente mais tarde.'
                this.$swal({
                  title: 'Aviso!',
                  text: text,
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-warning btn-fill'
                  },
                  icon: 'warning'
                })
              },
            true)
          }
        })
      }
    },
  };
</script>
<style>
  .no-border-card .card-footer {
    border-top: 0;
  }
</style>
